import { ajax } from "@/api/request";

// 酒店配置权限列表
export const get_sys_permission = function(params) {
  return ajax({
    method: 'get',
    url: '/permission/v1/getSysPermissionConfigList',
    params
  });
}

// 酒店权限查询
export const get_sys_permission_detail = function(params) {
  return ajax({
    method: 'get',
    url: '/permission/v1/querySysPermissionConfig',
    params
  });
}

// 酒店权限保存
export const save_sys_permission = function(data) {
  return ajax({
    method: 'post',
    url: '/permission/v1/saveSysPermissionConfig',
    data
  });
}

// 酒店权限更新
export const update_sys_permission = function(data) {
  return ajax({
    method: 'post',
    url: '/permission/v1/updateSysPermissionConfig',
    data
  });
}

// 酒店权限 - 上级菜单
export const get_upper_menu = function(params) {
  return ajax({
    method: 'get',
    url: '/permission/v1/sysUpperMenuList',
    params
  });
}

// 酒店角色列表
export const get_hotel_role = function(params) {
  return ajax({
    method: 'get',
    url: '/permission/v1/sysPermissionRoleList',
    params
  });
}

// 酒店角色授权
export const bind_role_permission = function(data) {
  return ajax({
    method: 'post',
    url: '/permission/v1/sysPermissionRoleBind',
    data
  });
}

// 获取优惠类型初始化列表
export const get_discountTypeInitListe = function(params) {
  return ajax({
    method: 'get',
    url: '/config/v1/getDiscountTypeInitList',
    params
  });
}

//修改优惠初始化类型
export const post_updateDiscountTypeInit = function(data) {
  return ajax({
    method: 'post',
    url: '/config/v1/updateDiscountTypeInit',
    data
  });
}

//启停酒店初始化配置项
export const post_changeHotelConfigInitFlag = function(params) {
  return ajax({
    method: 'post',
    url: '/config/v1/changeHotelConfigInitFlag',
    params
  });
}

//删除酒店初始化配置项
export const post_removeHotelConfigInit = function(params) {
  return ajax({
    method: 'post',
    url: '/config/v1/removeHotelConfigInit',
    params
  });
}

//新增优惠初始化类型
export const post_addDiscountTypeInit = function(data) {
  return ajax({
    method: 'post',
    url: '/config/v1/addDiscountTypeInit',
    data
  });
}

// 获取服务类型初始化列表
export const get_serviceTypeInitList = function(params) {
  return ajax({
    method: 'get',
    url: '/config/v1/getServiceTypeInitList',
    params
  });
}

//新增服务初始化类型
export const post_addServiceTypeInit = function(data) {
  return ajax({
    method: 'post',
    url: '/config/v1/addServiceTypeInit',
    data
  });
}

//修改服务初始化类型
export const post_updateServiceTypeInit = function(data) {
  return ajax({
    method: 'post',
    url: '/config/v1/updateServiceTypeInit',
    data
  });
}

// 获取客户等级初始化列表
export const get_customerLevelInitList = function(params) {
  return ajax({
    method: 'get',
    url: '/config/v1/getCustomerLevelInitList',
    params
  });
}  

//新增客户等级初始化类型
export const post_addCustomerLevelInit = function(data) {
  return ajax({
    method: 'post',
    url: '/config/v1/addCustomerLevelInit',
    data
  });
}

//修改客户等级初始化类型
export const post_updateCustomerLevelInit = function(data) {
  return ajax({
    method: 'post',
    url: '/config/v1/updateCustomerLevelInit',
    data
  });
}

//获取省市区列表
export const post_allAreaList = function(data) {
  return ajax({
    method: 'post',
    url: '/sys/v1/getAllAreaList',
    data
  });
}  

//码表分页查询
export const post_dictList = function(data) {
  return ajax({
    method: 'post',
    url: '/sys/v1/getDictList',
    data
  });
}  

//修改码表信息（子）
export const post_updateDictById = function(data) {
  return ajax({
    method: 'post',
    url: '/sys/v1/updateDictById',
    data
  });
} 

//修改码表信息（父）
export const post_updateDictTypeName = function(params) {
  return ajax({
    method: 'post',
    url: '/sys/v1/updateDictTypeName',
    params
  });
} 

//码表启停（父）
export const post_changeDictValidByType = function(params) {
  return ajax({
    method: 'post',
    url: '/sys/v1/changeDictValidByType',
    params
  });
} 

//码表启停（子）
export const post_changeDictValidById = function(params) {
  return ajax({
    method: 'post',
    url: '/sys/v1/changeDictValidById',
    params
  });
} 

//码表删除
export const post_removeDictById = function(params) {
  return ajax({
    method: 'post',
    url: '/sys/v1/removeDictById',
    params
  });
} 

//码表新增
export const post_addDict = function(data) {
  return ajax({
    method: 'post',
    url: '/sys/v1/addDict',
    data
  });
}

// 获取标签组颜色
export const get_label_color_list = function(params) {
  return ajax({
    method: 'get',
    url: '/config/v1/getSysCustomerLabelGroupColorList',
    params
  });
}

// 获取标签组
export const get_label_group_list = function(params) {
  return ajax({
    method: 'get',
    url: '/config/v1/getSysCustomerLabelGroupList',
    params
  });
}

// 新建标签组
export const add_label_group = function (data) {
  return ajax({
    method: 'post',
    url: '/config/v1/saveSysCustomerLabelGroup',
    data
  });
}

// 修改标签组
export const update_label_group = function (data) {
  return ajax({
    method: 'post',
    url: '/config/v1/updateSysCustomerLabelGroup',
    data
  });
}

// 启用、禁用标签组
export const stopOrEnable_label_group = function (data) {
  return ajax({
    method: 'post',
    url: '/config/v1/stopOrEnableSysCustomerLabelGroup',
    data
  });
}

// 删除标签组
export const delete_label_group = function(data) {
  return ajax({
    method: 'post',
    url: '/config/v1/deleteSysCustomerLabelGroup',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
}

// 获取标签
export const get_label_list = function(params) {
  return ajax({
    method: 'get',
    url: '/config/v1/getSysCustomerLabelList',
    params
  });
}

// 新建标签
export const add_label = function (data) {
  return ajax({
    method: 'post',
    url: '/config/v1/saveSysCustomerLabel',
    data
  });
}

// 修改标签
export const update_label = function (data) {
  return ajax({
    method: 'post',
    url: '/config/v1/updateSysCustomerLabel',
    data
  });
}

// 删除标签
export const delete_label = function(params) {
  return ajax({
    method: 'post',
    url: '/config/v1/deleteSysCustomerLabel',
    params
  });
}

//修改初始化排序
export const post_changeSort = function(data) {
  return ajax({
    method: 'post',
    url: '/config/v1/changeHotelConfigInitSort',
    data
  });
}

//新增组织机构
export const post_addDepartmentInit = function(data) {
  return ajax({
    method: 'post',
    url: '/config/v1/addDepartmentInit',
    data
  });
}

//新增集团组织机构
export const post_addBrandDepartmentInit = function(data) {
  return ajax({
    method: 'post',
    url: '/config/v1/addBrandDepartmentInit',
    data
  });
}

//修改组织机构
export const post_updateDepartmentInit = function(data) {
  return ajax({
    method: 'post',
    url: '/config/v1/updateDepartmentInit',
    data
  });
}

//修改组织机构
export const post_updateBrandDepartmentInit = function(data) {
  return ajax({
    method: 'post',
    url: '/config/v1/updateBrandDepartmentInit',
    data
  });
}

//组织机构列表
export const get_getDepartmentInitList = function(params) {
  return ajax({
    method: 'get',
    url: '/config/v1/getDepartmentInitList',
    params
  });
}

//集团组织机构列表
export const get_brandDepartmentInitList = function(params) {
  return ajax({
    method: 'get',
    url: '/config/v1/getBrandDepartmentInitList',
    params
  });
}

//新增宴会类型
export const post_addMeetingTypeInit = function(data) {
  return ajax({
    method: 'post',
    url: '/config/v1/addMeetingTypeInit',
    data
  });
}

//新增零点预订类型
export const post_addResvTypeInit = function(data) {
  return ajax({
    method: 'post',
    url: '/config/v1/addZeroResvTypeInitList',
    data
  });
}

//修改宴会类型
export const post_updateMeetingTypeInit = function(data) {
  return ajax({
    method: 'post',
    url: '/config/v1/updateMeetingTypeInit',
    data
  });
}

//修改零点预订类型
export const post_updateResvTypeInit = function(data) {
  return ajax({
    method: 'post',
    url: '/config/v1/updateZeroResvTypeInitList',
    data
  });
}

//宴会类型列表
export const get_getMeetingTypeInitList = function(params) {
  return ajax({
    method: 'get',
    url: '/config/v1/getMeetingTypeInitList',
    params
  });
}
//零点预订类型列表
export const get_getResvTypeInitList = function(params) {
  return ajax({
    method: 'get',
    url: '/config/v1/getZeroResvTypeInitList',
    params
  });
}
//新增无效原因
export const post_addClueInvalidReasonInit = function(data) {
  return ajax({
    method: 'post',
    url: '/config/v1/addClueInvalidReasonInit',
    data
  });
}
//修改无效原因
export const post_updateClueInvalidReasonInit = function(data) {
  return ajax({
    method: 'post',
    url: '/config/v1/updateClueInvalidReasonInit',
    data
  });
}
//无效原因列表
export const get_getClueInvalidReasonInitList = function(params) {
  return ajax({
    method: 'get',
    url: '/config/v1/getClueInvalidReasonInitList',
    params
  });
}

//新增支付方式
export const post_addPayTypeInit = function(data) {
  return ajax({
    method: 'post',
    url: '/config/v1/addPayTypeInit',
    data
  });
}
//修改支付方式
export const post_updatePayTypeInit = function(data) {
  return ajax({
    method: 'post',
    url: '/config/v1/updatePayTypeInit',
    data
  });
}
//支付方式列表
export const get_getPayTypeInitList = function(params) {
  return ajax({
    method: 'get',
    url: '/config/v1/getPayTypeInitList',
    params
  });
}

//新增收款项目
export const post_addCollectionItemInit = function(data) {
  return ajax({
    method: 'post',
    url: '/config/v1/addCollectionItemInit',
    data
  });
}
//修改收款项目
export const post_updateCollectionItemInit = function(data) {
  return ajax({
    method: 'post',
    url: '/config/v1/updateCollectionItemInit',
    data
  });
}
//收款项目列表
export const get_getCollectionItemInitList = function(params) {
  return ajax({
    method: 'get',
    url: '/config/v1/getCollectionItemInitList',
    params
  });
}

//修改销售机会等级
export const post_updatePreOrderLevelInit = function(data) {
  return ajax({
    method: 'post',
    url: '/config/v1/updatePreOrderLevelInit',
    data
  });
}
//销售机会等级列表
export const get_getPreOrderLevelInitList = function(params) {
  return ajax({
    method: 'get',
    url: '/config/v1/getPreOrderLevelInitList',
    params
  });
}

//获取通用配置接口
export const get_hotelConfigInitConfigTypeList = function(params) {
  return ajax({
    method: 'get',
    url: '/config/v1/getHotelConfigInitConfigTypeList',
    params
  });
}

//修改通用配置
export const post_updateHotelConfigInitConfigType = function(data) {
  return ajax({
    method: 'post',
    url: '/config/v1/updateHotelConfigInitConfigType',
    data
  });
}

//新增输单原因
export const post_addLossOrderReasonInit = function(data) {
  return ajax({
    method: 'post',
    url: '/config/v1/addLossOrderReasonInit',
    data
  });
}

//修改输单原因
export const post_updateLossOrderReasonInit = function(data) {
  return ajax({
    method: 'post',
    url: '/config/v1/updateLossOrderReasonInit',
    data
  });
}
//输单原因列表
export const get_getLossOrderReasonInitList = function(params) {
  return ajax({
    method: 'get',
    url: '/config/v1/getLossOrderReasonInitList',
    params
  });
}

//新增退款原因
export const post_addRefundReasonInit = function(data) {
  return ajax({
    method: 'post',
    url: '/config/v1/addRefundReasonInit',
    data
  });
}

//修改退款原因
export const post_updateRefundReasonInit = function(data) {
  return ajax({
    method: 'post',
    url: '/config/v1/updateRefundReasonInit',
    data
  });
}
//退款原因列表
export const get_getRefundReasonInitList = function(params) {
  return ajax({
    method: 'get',
    url: '/config/v1/getRefundReasonInitList',
    params
  });
}

//新增退款原因
export const post_addHotelServerTeamInit = function(data) {
  return ajax({
    method: 'post',
    url: '/config/v1/addHotelServerTeamInit',
    data
  });
}

//修改退款原因
export const post_updateHotelServerTeamInit = function(data) {
  return ajax({
    method: 'post',
    url: '/config/v1/updateHotelServerTeamInit',
    data
  });
}
//退款原因列表
export const get_getHotelServerTeamInitList = function(params) {
  return ajax({
    method: 'get',
    url: '/config/v1/getHotelServerTeamInitList',
    params
  });
}

//新增订单取消原因
export const post_addOrderCancelReasonInit = function(data) {
  return ajax({
    method: 'post',
    url: '/config/v1/addOrderCancelReasonInit',
    data
  });
}

//修改订单取消原因
export const post_updateOrderCancelReasonInit = function(data) {
  return ajax({
    method: 'post',
    url: '/config/v1/updateOrderCancelReasonInit',
    data
  });
}
//订单取消原因列表
export const get_getOrderCancelReasonInitList = function(params) {
  return ajax({
    method: 'get',
    url: '/config/v1/getOrderCancelReasonInitList',
    params
  });
}

//新增角色
export const post_addGlobalRole = function(data) {
  return ajax({
    method: 'post',
    url: '/config/v1/addGlobalRole',
    data
  });
}

//修改角色
export const post_updateGlobalRole = function(data) {
  return ajax({
    method: 'post',
    url: '/config/v1/updateGlobalRole',
    data
  });
}

//删除角色
export const post_deleteGlobalRole = function(params) {
  return ajax({
    method: 'post',
    url: '/config/v1/deleteGlobalRole',
    params
  });
}

//角色列表
export const get_getGlobalRoleList = function(params) {
  return ajax({
    method: 'get',
    url: '/config/v1/getGlobalRoleList',
    params
  });
}

//获取角色对应权限列表
export const get_getGlobalRolePermissionList = function(params) {
  return ajax({
    method: 'get',
    url: '/config/v1/getGlobalRolePermissionList',
    params
  });
}

//角色权限绑定
export const post_globalRolePermissionBind = function(data) {
  return ajax({
    method: 'post',
    url: '/config/v1/globalRolePermissionBind',
    data
  });
}

//角色权限解绑
export const post_globalRolePermissionUnBind = function(data) {
  return ajax({
    method: 'post',
    url: '/config/v1/globalRolePermissionUnBind',
    data
  });
}

//修改餐段
export const post_updateMealTypeInit = function(data) {
  return ajax({
    method: 'post',
    url: '/config/v1/updateMealTypeInit',
    data
  });
}

//修改零点餐段
export const post_updateZeroMealTypeInit = function(data) {
  return ajax({
    method: 'post',
    url: '/config/v1/updateZeroMealTypeInitList',
    data
  });
}

//餐段列表
export const get_getMealTypeInitList = function(params) {
  return ajax({
    method: 'get',
    url: '/config/v1/getMealTypeInitList',
    params
  });
}

//零点餐段列表
export const get_getZeroMealTypeInitList = function(params) {
  return ajax({
    method: 'get',
    url: '/config/v1/getZeroMealTypeInitList',
    params
  });
}
//新增一级来源
export const post_addPrimarySourceInit = function(data) {
  return ajax({
    method: 'post',
    url: '/config/v1/addPrimarySourceInit',
    data
  });
}

//修改一级来源
export const post_updatePrimarySourceInit = function(data) {
  return ajax({
    method: 'post',
    url: '/config/v1/updatePrimarySourceInit',
    data
  });
}
//一级来源列表
export const get_getPrimarySourceInitList = function(params) {
  return ajax({
    method: 'get',
    url: '/config/v1/getPrimarySourceInitList',
    params
  });
}
//新增二级来源
export const post_addSecondSourceInit = function(data) {
  return ajax({
    method: 'post',
    url: '/config/v1/addSecondSourceInit',
    data
  });
}

//修改二级来源
export const post_updateSecondSourceInit = function(data) {
  return ajax({
    method: 'post',
    url: '/config/v1/updateSecondSourceInit',
    data
  });
}
//二级来源列表
export const get_getSecondSourceInitList = function(params) {
  return ajax({
    method: 'get',
    url: '/config/v1/getSecondSourceInitList',
    params
  });
}
//新增区域
export const post_addTableAreaInit = function(data) {
  return ajax({
    method: 'post',
    url: '/config/v1/addTableAreaInit',
    data
  });
}
//修改区域
export const post_updateTableAreaInit = function(data) {
  return ajax({
    method: 'post',
    url: '/config/v1/updateTableAreaInit',
    data
  });
}
//区域列表
export const get_getTableAreaInitList = function(params) {
  return ajax({
    method: 'get',
    url: '/config/v1/getTableAreaInitList',
    params
  });
}

//新增宴会厅
export const post_addMeetingRoomInit = function(data) {
  return ajax({
    method: 'post',
    url: '/config/v1/addMeetingRoomInit',
    data
  });
}

//修改宴会厅
export const post_updateMeetingRoomInit = function(data) {
  return ajax({
    method: 'post',
    url: '/config/v1/updateMeetingRoomInit',
    data
  });
}
//宴会厅列表
export const get_getMeetingRoomInitList = function(params) {
  return ajax({
    method: 'get',
    url: '/config/v1/getMeetingRoomInitList',
    params
  });
}

//新增标签
export const post_addMeetingRoomTagInit = function(data) {
  return ajax({
    method: 'post',
    url: '/config/v1/addMeetingRoomTagInit',
    data
  });
}

//修改标签
export const post_updateMeetingRoomTagInit = function(data) {
  return ajax({
    method: 'post',
    url: '/config/v1/updateMeetingRoomTagInit',
    data
  });
}
//标签列表
export const get_getMeetingRoomTagInitList = function(params) {
  return ajax({
    method: 'get',
    url: '/config/v1/getMeetingRoomTagInitList',
    params
  });
}

//新增同行人
export const post_addPartnerIdentityInit = function(data) {
  return ajax({
    method: 'post',
    url: '/config/v1/addPartnerIdentityInit',
    data
  });
}

//修改同行人
export const post_updatePartnerIdentityInit = function(data) {
  return ajax({
    method: 'post',
    url: '/config/v1/updatePartnerIdentityInit',
    data
  });
}

//同行人列表
export const get_getPartnerIdentityInitList = function(params) {
  return ajax({
    method: 'get',
    url: '/config/v1/getPartnerIdentityInitList',
    params
  });
}

//跟新角色数据权限
export const post_updateGlobalRoleDataPermission = function(data) {
  return ajax({
    method: 'post',
    url: '/config/v1/updateGlobalRoleDataPermission',
    data
  });
}

//跟新集团角色数据权限
export const post_updateGlobalRoleBrandDataPermission = function(data) {
  return ajax({
    method: 'post',
    url: '/config/v1/updateGlobalRoleBrandDataPermission',
    data
  });
}

//跟新集团门店角色数据权限
export const post_updateGlobalRoleHotelDataPermission = function(data) {
  return ajax({
    method: 'post',
    url: '/config/v1/updateGlobalRoleHotelDataPermission',
    data
  });
}

// 修改角色功能权限
export const post_updateGlobalRoleFunctionPermission = function(data) {
  return ajax({
    method: 'post',
    url: '/config/v1/updateGlobalRoleFunctionPermission',
    data
  });
}

//获取线索任务配置
export const get_clueTaskConfig = function (data) {
  return ajax({
    method: 'get',
    url: '/hotelConfig/v1/getClueTaskConfig',
    params: data
  });
}

//修改线索任务配置
export const post_updateClueTaskConfig = function (data) {
  return ajax({
    method: 'post',
    url: '/hotelConfig/v1/updateClueTaskConfig',
    data: data
  });
}

//获取销售机会任务配置
export const get_preOrderTaskConfig = function (data) {
  return ajax({
    method: 'get',
    url: '/hotelConfig/v1/getPreOrderTaskConfig',
    params: data
  });
}

// 销售机会任务配置修改
export const post_updatePreOrderTaskConfig = function (data) {
  return ajax({
    method: 'post',
    url: '/hotelConfig/v1/updatePreOrderTaskConfig',
    data: data
  });
}

// 获取单期类型划分规则
export const get_date_type_list = function (params) {
  return ajax({
    method: 'get',
    url: '/config/v1/getDateTypeInitList',
    params
  });
}

// 修改单期类型划分规则
export const update_date_type = function (data) {
  return ajax({
    method: 'post',
    url: '/config/v1/updateDateTypeInit',
    data
  });
}

// 获取订单修改审批流规则
export const get_approval_flow_list = function (params) {
  return ajax({
    method: 'get',
    url: '/config/v1/getApprovalFlowRuleInitList',
    params
  });
}

// 修改订单修改审批流规则
export const update_approval_flow = function (data) {
  return ajax({
    method: 'post',
    url: '/config/v1/updateApprovalFlowRuleInit',
    data
  });
}

// // 获取二销业绩计算规则
// export const get_secondSale_achievement = function (params) {
//   return ajax({
//     method: 'get',
//     url: '/config/v1/getSecondSaleAchievementInit',
//     params
//   });
// }

// // 修改二销业绩计算规则
// export const update_secondSale_achievement = function (data) {
//   return ajax({
//     method: 'post',
//     url: '/config/v1/setSecondSaleAchievementInit',
//     data
//   });
// }

// // 获取回款负责人
// export const get_chargeUser = function (params) {
//   return ajax({
//     method: 'get',
//     url: '/config/v1/getChargeUserInit',
//     params
//   });
// }

// // 修改回款负责人
// export const update_chargeUser = function (data) {
//   return ajax({
//     method: 'post',
//     url: '/config/v1/setChargeUserInit',
//     data
//   });
// }

// 获取订单服务阶段
export const get_server_stage_list = function (params) {
  return ajax({
    method: 'get',
    url: '/config/v1/getOrderSeverStageInit',
    params
  });
}

// 更新订单服务阶段
export const update_server_stage_list = function (data) {
  return ajax({
    method: 'post',
    url: '/config/v1/updateOrderSeverStageInit',
    data
  });
}

// 获取订单任务规则
export const get_task_config_list = function (params) {
  return ajax({
    method: 'get',
    url: '/config/v1/getTaskAutoSendInit',
    params
  });
}

// 新增订单任务规则
export const add_task_config = function (data) {
  return ajax({
    method: 'post',
    url: '/config/v1/saveTaskAutoSendInit',
    data
  });
}

// 修改订单任务规则
export const update_task_config = function (data) {
  return ajax({
    method: 'post',
    url: '/config/v1/updateTaskAutoSendInit',
    data
  });
}

// 删除订单任务规则
export const delete_task_config = function (data) {
  return ajax({
    method: 'post',
    url: '/config/v1/deleteTaskAutoSendInit',
    params: data
  });
}

// 订单任务规则排序
export const sort_task_config = function (data) {
  return ajax({
    method: 'post',
    url: '/config/v1/updateTaskAutoSendInitSort',
    data
  });
}

// // 获取订单任务模板
// export const get_task_template_list = function (params) {
//   return ajax({
//     method: 'get',
//     url: '/config/v1/getTaskTemplateInitList',
//     params
//   });
// }

// // 新增订单任务模板
// export const add_task_template = function (data) {
//   return ajax({
//     method: 'post',
//     url: '/config/v1/addTaskTemplateInit',
//     data
//   });
// }

// // 修改订单任务模板
// export const update_task_template = function (data) {
//   return ajax({
//     method: 'post',
//     url: '/config/v1/updateTaskTemplateInit',
//     data
//   });
// }

// // 排序订单任务模板
// export const sort_task_template = function (data) {
//   return ajax({
//     method: 'post',
//     url: '/config/v1/changeTaskTemplateInitSort',
//     data
//   });
// }

// // 删除订单任务模板
// export const delete_task_template = function (data) {
//   return ajax({
//     method: 'post',
//     url: '/config/v1/deleteTaskTemplateInit',
//     params: data
//   });
// }

// 短信模板列表
export const get_sms_template_list = function (params) {
  return ajax({
    method: 'get',
    url: '/msgConfig/v1/getMsgTemplates',
    params
  });
}

// 短信模板详情
export const get_sms_template_detail = function (params) {
  return ajax({
    method: 'get',
    url: '/msgConfig/v1/getMsgTemplateDetail',
    params
  });
}

// 短信模板参数
export const get_sms_template_params = function (params) {
  return ajax({
    method: 'get',
    url: '/msgConfig/v1/getSmsTemplateParams',
    params
  });
}

// 修改短信模板
export const update_sms_template = function (data) {
  return ajax({
    method: 'post',
    url: '/msgConfig/v1/updateMsgTemplateDetail',
    data
  });
}

// 短信模板参数
export const get_sms_template_detail_by_type = function (params) {
  return ajax({
    method: 'get',
    url: '/msgConfig/v1/getMsgTemplateDetailByType',
    params
  });
}

//获取宴会类型列表
export const get_MeetingTypeAttributeInitList = function (params) {
  return ajax({
    method: 'get',
    url: '/config/v1/getMeetingTypeAttributeInitList',
    params
  });
}

//新增宴会类型属性
export const post_addMeetingTypeAttributeInit = function(data) {
  return ajax({
    url: '/config/v1/addMeetingTypeAttributeInit',
    method: 'post',
    data
  });
}

//修改宴会类型属性
export const post_updateMeetingTypeAttributeInit = function(data) {
  return ajax({
    url: '/config/v1/updateMeetingTypeAttributeInit',
    method: 'post',
    data
  });
}

//获取菜品类别列表
export const get_dishTypeInitList = function (params) {
  return ajax({
    method: 'get',
    url: '/config/v1/getDishTypeInitList',
    params
  });
}

//新增菜品类别
export const post_addDishTypeInit = function(data) {
  return ajax({
    url: '/config/v1/addDishTypeInit',
    method: 'post',
    data
  });
}

//修改菜品类别
export const post_updateDishTypeInit = function(data) {
  return ajax({
    url: '/config/v1/updateDishTypeInit',
    method: 'post',
    data
  });
}


//获取eo单模板内容
export const get_EoTemplateDetail = function (params) {
  return ajax({
    method: 'get',
    url: '/hotelConfig/v1/getEoTemplateDetail',
    params
  });
}

//修改eo模板详情
export const post_updateEoTemplateDetail = function (data) {
  return ajax({
    method: 'post',
    url: '/hotelConfig/v1/updateEoTemplateDetail',
    data
  });
}

// // 获取二销回款负责人
// export const get_SecondSaleChargeUser = function (params) {
//   return ajax({
//     method: 'get',
//     url: '/config/v1/getSecondSaleChargeUserInit',
//     params
//   });
// }

// // 修改二销回款负责人
// export const set_SecondSaleChargeUser = function (data) {
//   return ajax({
//     method: 'post',
//     url: '/config/v1/setSecondSaleChargeInit',
//     data
//   });
// }

//获取权益列表
export const get_rightsAndInterestsTypeInitList = function (params) {
  return ajax({
    method: 'get',
    url: '/config/v1/getRightsAndInterestsTypeInitList',
    params
  });
}

//新增权益
export const post_addRightsAndInterestsTypeInit = function (data) {
  return ajax({
    method: 'post',
    url: '/config/v1/addRightsAndInterestsTypeInit',
    data
  });
}

//修改权益
export const post_updateRightsAndInterestsTypeInit = function (data) {
  return ajax({
    method: 'post',
    url: '/config/v1/updateRightsAndInterestsTypeInit',
    data
  });
}

//删除权益
export const post_deleteRightsAndInterestsInit = function (params) {
  return ajax({
    method: 'post',
    url: '/config/v1/deleteRightsAndInterestsInit',
    params
  });
}

//获取客户线索列表
export const get_YhjClueList = function (data) {
  return ajax({
    method: 'post',
    url: '/clue/v1/getYhjClueList',
    data
  });
}

//修改客户线索状态
export const post_updateYhjClue = function (data) {
  return ajax({
    method: 'post',
    url: '/clue/v1/updateYhjClue',
    data
  });
}

//获取短信通知列表
export const get_YhjNoticeList = function (params) {
  return ajax({
    method: 'get',
    url: '/notice/v1/getYhjNoticeList',
    params
  });
}

//修改短信通知列表人员
export const post_updateYhjNotice = function (data) {
  return ajax({
    method: 'post',
    url: '/notice/v1/updateYhjNotice',
    data
  });
}

//获取服务套餐类别列表
export const get_serverTypeList = function (data) {
  return ajax({
    method: 'get',
    url: '/config/v1/getServerComboTypeInitList',
    params: data
  });
}

//新增服务套餐类别
export const post_addServerComboTypeInit = function(data) {
  return ajax({
    url: '/config/v1/addServerComboTypeInit',
    method: 'post',
    data
  });
}

//修改服务套餐类别
export const post_updateServerComboTypeInit = function(data) {
  return ajax({
    url: '/config/v1/updateServerComboTypeInit',
    method: 'post',
    data
  });
}

//删除服务套餐类别
export const post_deleteServerComboTypeInit = function(params) {
  return ajax({
    method: 'post',
    url: '/config/v1/deleteServerComboTypeInit',
    params
  });
}

//获取合同模板列表
export const get_contractInitTemplate = function (params) {
  return ajax({
    method: 'get',
    url: '/hotelConfig/v1/getContractInitTemplate',
    params
  });
}

//获取合同模板参数
export const get_contractTemplateParams = function (params) {
  return ajax({
    method: 'get',
    url: '/hotelConfig/v1/getContractTemplateParams',
    params
  });
}

//保存合同模板
export const post_saveContractTemplate = function (data) {
  return ajax({
    method: 'post',
    url: '/hotelConfig/v1/saveContractTemplate',
    data
  });
}

//预览合同
export const get_contractTemplateContent = function (data) {
  return ajax({
    method: 'post',
    url: '/hotelConfig/v1/getContractTemplateContentPreview',
    data
  });
}

//新增销售机会规则
export const post_savePreOrderTaskConfig = function (data) {
  return ajax({
    method: 'post',
    url: '/hotelConfig/v1/savePreOrderTaskConfig',
    data
  });
}

//删除销售机会规则
export const post_deletePreOrderTaskConfig = function (data) {
  return ajax({
    method: 'post',
    url: '/hotelConfig/v1/deletePreOrderTaskConfig',
    data
  });
}

//删除来源分组
export const get_deletePrimarySource = function (data) {
  return ajax({
    method: 'get',
    url: '/hotelConfig/v1/deletePrimarySource',
    params: data
  });
}